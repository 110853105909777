@import './common.scss';

main {
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;

  div {
    width: fit-content;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }

  h1 {
    margin: 0 0 48px;
  }

  p {
    align-self: flex-end;
    display: flex;
    align-items: center;

    button {
      transition: box-shadow $animation-speed-fast;
      padding: $spacing-x-small $spacing-small;
    }
  }
}
